import request from '@/plugins/axios'

// ================关键词任务=====================
export function getKeywordMonitorTask(id) {
  // 获取指定ID的关键词任务
  return request({
    url: `/monitor/keyword/${id}`,
    method: 'get'
    // data: params
  })
}

export function getKeywordMonitorTasks(params) {
  // 获取twitter帐号列表
  return request({
    url: '/monitor/keyword',
    method: 'get',
    params: params
  })
}

export function addKeywordMonitorTask(keyword) {
  // 获取twitter帐号列表
  return request({
    url: '/monitor/keyword',
    method: 'post',
    data: {
      keyword
    }
  })
}

export function delKeyword(id) {
  // 删除
  return request({
    url: `/monitor/keyword/${id}`,
    method: 'delete'
  })
}

// ================用户监控任务=====================

export function getUserMonitorTasks(params) {
  // 获取twitter帐号列表
  return request({
    url: '/monitor/user',
    method: 'get',
    params: params
  })
}

export function addUserMonitorTask(redditUserName) {
  // 获取twitter帐号列表
  return request({
    url: '/monitor/user',
    method: 'post',
    data: {
      redditUserName
    }
  })
}

export function delUser(id) {
  // 删除
  return request({
    url: `/monitor/user/${id}`,
    method: 'delete'
  })
}

// ================帖子监控任务=====================

export function getPostMonitorTasks(params) {
  // 获取twitter帐号列表
  return request({
    url: '/monitor/post',
    method: 'get',
    params: params
  })
}

export function addPostMonitorTask(postId) {
  // 获取twitter帐号列表
  return request({
    url: '/monitor/post',
    method: 'post',
    data: {
      postId
    }
  })
}
export function delPost(id) {
  // 删除
  return request({
    url: `/monitor/post/${id}`,
    method: 'delete'
  })
}

// ================社区监控任务=====================

export function getSubredditMonitorTasks(params) {
  // 获取twitter帐号列表
  return request({
    url: '/monitor/subreddit',
    method: 'get',
    params: params
  })
}

export function addSubredditMonitorTask(subredditName) {
  // 获取twitter帐号列表
  return request({
    url: '/monitor/subreddit',
    method: 'post',
    data: {
      subredditName
    }
  })
}

export function delSubre(id) {
  // 删除
  return request({
    url: `/monitor/subreddit/${id}`,
    method: 'delete'
  })
}
