import request from '@/plugins/axios'

// ================用户=====================
export function getUserById(id) {
  // 获取指定ID的用户
  return request({
    url: `/reddit/users/${id}`,
    method: 'get'
    // data: params
  })
}

export function getUsersByQuery(params) {
  // 根据查询条件获取reddit用户信息
  const data = {}
  Object.keys(params).forEach(key => {
    if (params[key]) {
      data[key] = params[key]
    }
  })

  // 获取斗鱼采集任务列表
  return request({
    url: `/reddit/users/list/${params.pageIndex}/${params.pageSize}`,
    method: 'get',
    params: data
  })
}

export function getPostsByQuery(params) {
  // 根据查询条件获取reddit帖子信息
  const data = {}
  Object.keys(params).forEach(key => {
    if (params[key]) {
      data[key] = params[key]
    }
  })

  // 获取斗鱼采集任务列表
  return request({
    url: '/reddit/posts',
    method: 'get',
    params: data
  })
}

export function getCommentsByQuery(params) {
  // 根据查询条件获取reddit评论信息
  const data = {}
  Object.keys(params).forEach(key => {
    if (params[key]) {
      data[key] = params[key]
    }
  })

  // 获取斗鱼采集任务列表
  return request({
    url: '/reddit/comments',
    method: 'get',
    params: data
  })
}

export function getSubredditsByQuery(params) {
  // 根据查询条件获取reddit评论信息
  const data = {}
  Object.keys(params).forEach(key => {
    if (
      (params[key] !== '') |
      (params[key] !== undefined) |
      (params[key] != null)
    ) {
      data[key] = params[key]
    }
  })

  // 获取斗鱼采集任务列表
  return request({
    url: '/reddit/subreddits',
    method: 'get',
    params: data
  })
}
