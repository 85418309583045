<template>
  <div class="search-index">
    <div class="search-index-left">
      <div>
        <el-form ref="form" :model="submitForm">
          <el-form-item>
            <el-input
              v-model.trim="submitForm.fullSubredditId"
              size="small"
              placeholder="请输入社区ID"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="submitForm.subredditName"
              clearable
              size="small"
              placeholder="请输入社区名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="submitForm.lang"
              clearable
              size="small"
              placeholder="请输入语种"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="submitForm.query"
              clearable
              size="small"
              placeholder="请输入关键词"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              type="datetime"
              placeholder="开始日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              size="small"
              v-model.trim="submitForm.createBeginTime"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              type="datetime"
              placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              size="small"
              v-model.trim="submitForm.createEndTime"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          size="mini"
          style="width:100%;margin-top:10px; border-radius: 16px;"
          @click="searchFun"
        >
          查 询
        </el-button>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          近10条历史查询记录
        </div>
        <div class="left-list-title-b"></div>
        <ul>
          <li v-for="(item, i) in listData" :key="i">
            <span
              @click="selectitem(i)"
              class="history-words"
              :class="selectIndex == i ? 'select-obj' : ''"
            >
              <!--  -->
              {{ item | listFilters }}
            </span>
          </li>
        </ul>
        <no-data
          fontSize="12px"
          text="暂无历史查询记录"
          v-if="listData.length == 0"
        />
      </div>
    </div>
    <div class="search-index-right" v-loading="listLoading">
      <totalAll
        v-if="contentList.length > 0"
        :text="'共检索到数据' + total + '条'"
      />
      <div
        class="search-index-right-child"
        v-infinite-scroll="fetchData"
        :infinite-scroll-disabled="scrollDisabled"
        :infinite-scroll-distance="10"
      >
        <div class="right-item" v-for="(item, i) in contentList" :key="i">
          <div class="right-item-top">
            <img :src="item.iconImg" alt="" :onerror="noPic" />
            <div>
              <div v-html="item.title"></div>
              <div>
                <span v-html="item.subredditName"></span>
              </div>
            </div>
          </div>
          <div class="right-item-memo-id">
            社区ID：
            <span>{{ item.fullSubredditId }}</span>
          </div>
          <div class="right-item-memo">
            简介：
            <span v-html="item.description"></span>
          </div>
          <div class="right-item-info">
            <div>
              <div>{{ item.subscribers }}人</div>
              <div>Members</div>
            </div>
            <div>
              <div>{{ item.lang }}</div>
              <div>语种</div>
            </div>
          </div>

          <div class="right-item-time">
            <!-- <img :src="require('../../assets/imgs/c_time.png')" alt="" /> -->
            <span>创建时间：{{ item.created }}</span>
            <div>
              <span>原链接：</span>
              <a
                :href="'https://www.reddit.com/' + item.displayNamePrefixed"
                target="_blank"
              >
                <span>reddit.com/{{ item.displayNamePrefixed }}</span>
              </a>
            </div>
          </div>

          <div class="right-item-bottom">
            <img :src="require('../../assets/imgs/look.png')" alt="" />
            <span @click="lookOver(item.subredditName)">查看帖子</span>
            <span @click="addMonitor(item.subredditName)">{{ addText }}</span>
          </div>
        </div>
        <noMorePage :nomore="nomore" v-if="contentList.length > 0" />
      </div>
      <no-data v-if="contentList.length == 0" />
    </div>
  </div>
</template>

<script>
import { addSubredditMonitorTask } from '../../api/monitor'
import { getSubredditsByQuery } from '../../api/reddit'
export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/r.jpg') + '"',
      submitForm: {
        fullSubredditId: '',
        subredditName: '',
        lang: '',
        query: '',
        createBeginTime: '',
        createEndTime: ''
      },
      addText: '一键添加社区方案',
      listLoading: false,
      listData: [],
      selectIndex: null,
      contentList: [],
      current: 1,
      limit: 40,
      total: 0,
      scrollDisabled: false,
      nomore: true
    }
  },
  filters: {
    listFilters(obj) {
      if (obj) {
        for (let i in obj) {
          if (obj[i] && obj[i].trim() !== '') {
            return obj[i]
          }
        }
      }
    }
  },
  created() {
    this.getLocalStorageFun()
  },
  methods: {
    lookOver(subreddit) {
      this.$router.push({
        path: '/search/posts',
        query: {
          subreddit: subreddit
        }
      })
    },
    // 一键添加方案
    addMonitor(subredditName) {
      addSubredditMonitorTask(subredditName).then(res => {
        this.$message({
          type: 'success',
          message: '添加成功'
        })
      })

      // this.$message({
      //   message: '添加成功',
      //   type: 'success'
      // })
    },
    searchFun() {
      this.selectIndex = null
      this.current = 1
      this.contentList = []
      this.fetchData()
      this.localStorageFun()
      this.getLocalStorageFun()
    },
    localStorageFun() {
      let arrString = localStorage.getItem('queryCommunity')
      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        if (arr.length < 10) {
          if (this.isEmpty(this.submitForm)) {
            arr.push(this.submitForm)
          }
        } else {
          if (this.isEmpty(this.submitForm)) {
            arr.shift()
            arr.push(this.submitForm)
          }
        }
      } else {
        arr = []
        if (this.isEmpty(this.submitForm)) {
          arr.push(this.submitForm)
        }
      }
      localStorage.setItem('queryCommunity', JSON.stringify(arr))
    },
    getLocalStorageFun() {
      let arrString = localStorage.getItem('queryCommunity')
      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        this.listData = [].concat(arr)
      }
    },
    isEmpty(obj) {
      let arr = []
      for (let i in obj) {
        if (obj[i] && obj[i].trim() !== '') {
          arr.push(obj[i])
        }
      }
      if (arr.length == 0) {
        return false
      } else {
        return true
      }
    },
    selectitem(index) {
      this.selectIndex = index
      this.current = 1
      this.contentList = []
      let obj = this.listData[index]
      this.submitForm = Object.assign({}, obj)
      this.fetchData()
    },
    async fetchData() {
      try {
        this.listLoading = true
        this.scrollDisabled = true
        let obj = Object.assign(
          { pageIndex: this.current, pageSize: this.limit },
          this.submitForm
        )
        const data = await getSubredditsByQuery(obj)

        this.total = data.all_count
        if (data.items.length > 0) {
          this.scrollDisabled = false
          this.current++
          this.contentList = this.contentList.concat(data.items)
          if (data.items.length < this.limit) {
            this.nomore = false
          }
        } else {
          this.nomore = false
        }
      } finally {
        this.listLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-index {
  width: 100%;
  height: calc(100vh - 79px);
  background: #f0f2f5;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .search-index-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    border: 1px solid #e5e5e5;
    .left-list {
      height: calc(100% - 305px);
      overflow: hidden;
      overflow-y: auto;
      position: relative;
      margin-top: 20px;
      .left-list-title {
        font-size: 14px;
        font-weight: 300;
        color: #2a2a2a;
        border-left: 4px solid #0079d3;
        padding-left: 10px;
      }
      .left-list-title-b {
        margin: 10px 0;
        border-bottom: 1px solid #fbf6f6;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          margin-bottom: 15px;
          .history-words {
            padding: 5px 10px;
            background: #efefef;
            border: 0px solid #000000;
            border-radius: 20px;

            font-size: 12px;
            font-weight: 300;
            color: #2a2a2a;
            cursor: pointer;
            user-select: none;
          }
          .select-obj {
            background: #ff4500;
            color: #ffffff;
          }
        }
      }
    }
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
  }
  .search-index-right {
    width: calc(100% - 270px);

    position: relative;
    // overflow: hidden;

    .search-index-right-child {
      overflow: hidden;
      overflow-y: auto;
      margin-top: 5px;
      width: calc(100% + 10px);
      height: calc(100% - 37px);

      .right-item {
        display: inline-block;
        width: calc(100% / 5 - 10px);
        // height: 320px;
        background: #ffffff;
        box-shadow: 0 1px 3px 0 #d8d9d9;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        padding: 10px;
        overflow: hidden;
        margin-right: 10px;
        margin-bottom: 10px;
        .right-item-top {
          width: 100%;
          display: flex;
          img {
            width: 49px;
            height: 48px;
            border-radius: 50%;
          }
          > div {
            width: calc(100% - 60px);
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            > div {
              font-size: 14px;
              color: #000;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              span {
                font-size: 12px;
              }
            }
            > div:nth-child(1) {
              font-weight: 600;
            }
          }
        }
        .right-item-memo-id {
          width: 100%;

          font-size: 12px;
          font-family: Microsoft YaHei;
          color: #777;
          line-height: 18px;

          margin: 10px 0;
        }
        .right-item-memo {
          width: 100%;
          height: 75px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          color: #777;
          line-height: 18px;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
          margin: 10px 0;
        }
        .right-item-info {
          width: 100%;
          display: flex;
          > div {
            width: 49%;
            overflow: hidden;
            div {
              font-size: 14px;
              font-weight: bold;
              color: #1a1a1b;
              line-height: 22px;
              white-space: nowrap;
            }
          }
        }
        .right-item-time {
          padding: 5px 0;
          border-top: 1px solid #f2f4f8;
          border-bottom: 1px solid #f2f4f8;
          margin: 5px 0;
          white-space: nowrap;
          img {
            width: 16px;
            height: auto;
            vertical-align: middle;
          }
          span {
            vertical-align: middle;
            font-size: 12px;
            color: #777;
            line-height: 22px;
            word-break: break-word;
            white-space: normal;
          }
        }
        .right-item-bottom {
          text-align: center;
          padding: 5px 0;
          cursor: pointer;
          white-space: nowrap;
          img {
            width: 16px;
            height: auto;
            vertical-align: middle;
          }
          span {
            vertical-align: middle;
            font-size: 12px;
            font-weight: 300;
            color: #424243;
            margin-left: 5px;
            display: inline-block;
            &:nth-last-child(2) {
              border-right: 1px solid #ddd;
              padding-right: 10px;
              margin-right: 10px;
            }
          }

          /* &:hover {
            span {
              color: #0079d3;
            }
          } */
        }
      }
    }

    .content-none {
      font-size: 22px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #898989;
    }
  }
}
</style>
